import { render, staticRenderFns } from "./company-identifier.html?vue&type=template&id=530a2ecd&scoped=true&external"
import script from "./company-identifier.js?vue&type=script&lang=js&external"
export * from "./company-identifier.js?vue&type=script&lang=js&external"
import style0 from "./company-identifier.scss?vue&type=style&index=0&id=530a2ecd&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530a2ecd",
  null
  
)

export default component.exports